::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #b2b6ba;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9aa0a5;
}
