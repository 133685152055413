body {
  min-width: 200px;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  max-height: 180px;
}

/* Quill Editor styles */
.quill {
  /* height: 300px; */
  min-height: 160px;
  max-height: 640px;
  overflow-y: scroll;
}

.quill > .ql-container.ql-snow {
  height: auto;
  /* max-width: 80%;
  margin: auto; */
}

.quill > .ql-container.ql-snow > .ql-editor {
  height: auto;
}

.ql-tooltip {
  left: auto !important;
  top: auto !important;
  transform: none !important;
}

/* Specific styles for ql-editing class */
.ql-tooltip.ql-editing {
  left: 0 !important;
  top: 0 !important;
}

/* Other Styles */
.task-type > div > input,
.task-type[aria-expanded='true'] > div > input[aria-controls='task-type-listbox'] {
  font-size: 12px;
  font-family: monospace, serif;
}
.react-stopwatch-timer__table {
  height: 50px !important;
}
.css-pv2e5z {
  margin-top: -1.2rem !important;
}
